import state from './store'
import { Canvas } from 'react-three-fiber'
import { Block } from './blocks'
import { Box, Shapes } from './Home'
import { Html } from 'drei'
import React, { useEffect, useRef, useState } from 'react'
import { HtmlContent } from './html-content'


export function App() {
  const [events, setEvents] = useState()
  const domContent = useRef()
  const scrollArea = useRef()
  // @ts-ignore
  const onScroll = (e) => {
    // @ts-ignore
    return (state.top.current = e.target.scrollTop)
  }
  useEffect(() => onScroll({ target: scrollArea.current }), [])
  return (
    <>
      <Canvas
        colorManagement
        gl={{ alpha: false, antialias: true }}
        camera={{ position: [0, 0, 4.5], fov: 50, near: 0.1, far: 100 }}
        onCreated={({ gl, events }) => {
          gl.setClearColor('white')
          gl.toneMappingExposure = 2.5
          // @ts-ignore
          gl.toneMappingWhitePoint = 1
          // Export canvas events, we will put them onto the scroll area
          // @ts-ignore
          setEvents(events)
        }}>
        <Block factor={1.5} offset={0}>
          <Shapes />
          {/*@ts-ignore*/}
          <HtmlContent portal={domContent}>
            <div
              style={{
                fontWeight: 800,
                display: 'flex',
                flexDirection: 'column'

              }}
            >
              <div
                style={{ paddingLeft: 24, paddingTop: 24, fontSize: 24, cursor: 'pointer' }}
                onClick={() => {
                  // @ts-ignore
                  window.location = 'https://concat.kr'
                }}
              >
                CONCAT Inc.
              </div>
              <div style={{
                fontSize: 48,
                position: 'absolute',
                top: '50%', left: '50%',
                transform: 'translate3d(-50%, -40%, 0)',
                lineHeight: 1.2
              }}>
                <div>
                  Metaverse
                </div>
                <div>
                  Illustration
                </div>
                <div>
                  Fair
                </div>
                <div
                  style={{
                    fontSize: 28, marginTop: 28,
                    textAlign: 'center'
                  }}
                >
                  2022.07
                </div>
                <div style={{ marginTop: 32, display: 'flex', justifyContent: 'center' }}>
                  <img
                    src={'/instagram.png'} style={{
                    width: 52, height: 52, padding: 8, cursor: 'pointer'
                  }}
                    onClick={() => {
                      // @ts-ignore
                      window.location = 'https://instagram.com/metaverse_fair?igshid=YmMyMTA2M2Y='
                    }}
                  />
                </div>
              </div>

            </div>
            {/*  <h2 style={{ fontSize: '2em', top: '4rem' }}>CONCAT Inc.</h2>*/}
            {/*</div>*/}
            {/*/!*<div className='menu right'>*!/*/}
            {/*/!*  <span>Login</span>*!/*/}
            {/*/!*  <span>Sign up</span>*!/*/}
            {/*/!*</div>*!/*/}
            {/*<div*/}
            {/*  className='jumbo'*/}
            {/*  // style={{ width: '100%', marginLeft: 16, marginRight: 16 }}*/}
            {/*>*/}
            {/*  <h1>*/}
            {/*    Metaverse*/}
            {/*  </h1>*/}
            {/*  <h1>*/}
            {/*    fair*/}
            {/*  </h1>*/}
            {/*  <h3>2022.04.22 ~ 2022.04.24</h3>*/}
            {/*  /!*<Categories />*!/*/}
            {/*</div>*/}
          </HtmlContent>
        </Block>
        <Block factor={-1} offset={1}>
          <Box />
          <Html center portal={domContent}>
            <div style={{
              display: 'flex', flexDirection: 'column',
              width: '100vw',
              // backgroundColor: 'red',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <h2>CONCAT Inc.</h2>
              <h5>
                <div>
                  회사주소: 서울시 테헤란로 623 삼성빌딩 14층
                </div>
                <div style={{ display: 'flex' }}>
                  문의:
                  <div style={{
                    textDecoration: 'underline', marginLeft: 4,
                    cursor: 'pointer'
                  }}

                       onClick={() => {
                         // @ts-ignore
                         window.location = 'mailto:contact@concat.kr'
                       }}>
                    contact@concat.kr</div>
                </div>
                <div>
                  사업자등록번호: 460-86-01068
                </div>
                <div>
                  통신판매업신고번호: 2018-서울서대문-0943
                </div>
              </h5>
            </div>
          </Html>
        </Block>
      </Canvas>
      <div
        className='scrollArea'
        ref={scrollArea}
        onScroll={onScroll}
        {...events}>
        <div style={{ position: 'sticky', top: 0 }} ref={domContent} />
        <div style={{ height: `${state.pages * 100}vh` }} />
      </div>
    </>
  )
}
